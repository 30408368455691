.settings-layout {
  // height: calc(100vh - 150px);
  // height: calc(100vh - 16.3vh);
  height: calc(100vh);

  &__wrapper {
    // display: flex;
    // height: 100%;
    display: flex;
    height: calc(100vh);
  }
  &__body {
    // position: relative;
    width: 100%;
    background: #f3f5f7;
    padding: 2.1rem 3rem 3.6rem 3rem;
    height: calc(100vh - 40px);
    // padding: 2.1rem 3rem 2.1rem 3rem;
  }

  &__closebtn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: end;
    padding:16px 29px 0px 0px ;
    background-color: #f3f5f7;
  }

  &__body-wrapper{
    width: 100%;
  }
}

// .global-layout {
//   height: 100vh;

//   &__body {
//     display: flex;
//     height: calc(100vh - 74px);
//     &--body {
//       width: 100%;
//       background: #f3f5f7;
//       padding: 2.1rem 3rem 3.6rem 3rem;
//     }
//   }
// }
