.d-flex {
    display: flex;

    &__ml-1 {
        margin-left: 5px;
    }
}


.card-title {
    font-weight: bold;
    margin-top: 2px;

    &__sub-text {
        font-size: 10px;
        font-weight: 400;
    }
}