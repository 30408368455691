.subText {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.445);

    &__mt {
        margin-top: -25px;
    }
}

.mt-1 {
    margin-top: 15px;
}

.fr {
    float: right
}