.dynamic-table {
    overflow: auto;
    background-color: #fff !important;
    border-radius: 10px;
    // max-height: calc(100vh ) !important;
    height: 77vh !important;

    &__pagination {
        justify-content: center !important;

        .ant-pagination-item {
            border-radius: 50%;
        }

        .ant-pagination-item-active {
            border-color: #000;
            background-color: #000;

            &:hover {
                border: none !important;
                border-width: 0px !important;
            }

            a {
                color: #fff !important;
            }
        }
    }

    .table-edit-icon,
    .table-delete-icon {
        cursor: pointer;
    }

    &__no-action {
        cursor: not-allowed;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        opacity: 40%;
    }

    .ant-table-wrapper .ant-table-pagination.ant-pagination {
        margin: 5px 20px;
    }
}


// .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
// .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
// .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
// .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
//     background: rgb(244, 244, 244) !important   ; //Change the existing color to `unset`
// }