.search-filter {
	
	&__wrapper {
		padding: 2rem;
		
	}
	&__search {
		width: 400px !important;
	}
}
