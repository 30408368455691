.ant-card .ant-card-body {
  padding: 1rem !important;
}

.selected {
  border: 2px solid #0b78c2;
}


.connectBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0b78c2;
  border: 1px solid #0b78c2 !important;
  font-size: 1.6rem;
  color: #fff;
  font-weight: 400;
  border-radius: 6px !important;
  gap: 8px;
  padding: 2rem 2.5rem;
  border: none;
  cursor: pointer;
}

.connectBtn:hover{
  background: #0b78c2  !important;
  // outline: none !important;
}

.disableBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0b78c2;
  border: 1px solid  !important;
  font-size: 1.6rem;
  color: #fff;
  font-weight: 400;
  border-radius: 6px !important;
  gap: 8px;
  padding: 2rem 2.5rem;
  border: none;
  cursor: pointer;
}