@import "../../../../scss/mixin.scss";

.req-detail-body {
  padding: 15px;
  margin-top: -25px;
//   width: 200px;
}

.content-heading {
  font-size: 16px;
  font-weight: 800;

  &__sub-heading {
    font-size: 12px;
    font-weight: 300;
  }
}

.card-width {
  width: 770px;

  @include mq("mid-desktop") {
    width: 690px;
  }
}

.ant-card-grid {
  padding: 12px !important;
}

.mt-1 {
  margin-top: 15px;
}

.req-header-title {
  font-size: 18px;
  font-weight: 600;

  &__sub-title {
    font-size: 14px;
    font-weight: 600;
  }
}

.fullUrl {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.token {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
}
