.subscription-card {
    overflow: auto !important;
    background-color: #fff !important;
    max-width: 500px;
    border-radius: 5px;
    min-height: 25vh;
}

.plan-details-title {
    font-size: 14px;
    vertical-align: middle;
}

.d-flex {
    display: flex;
    justify-content: space-between;
    padding: 25px;
}

.button {
    min-width: 14rem;
    min-height: 3rem;
    border: 1px solid #0b78c2;
    border-radius: 0.8rem;
    color: #0b78c2;
    background-color: #fff;
    font-size: 14px;
    padding: 5px;
}

.button:hover {
    cursor: pointer;
    border: 1px solid #0b78c2;
    color: #fff;
    background-color: #0b78c2;
}

.divider-line {
    margin: 0px 25px 0px 25px;
}

.curr-plan-title {
    font-size: 18px;
}

.mrg-right {
    margin-right: 145px;
}

.plan-type {
    font-size: 16px;
    font-weight: 800;
    color: #0b78c2;
}

.plan-pricing {
    font-size: 22px;
    font-weight: 400;
    color: #0b78c2;

    &__sub-text {
        font-size: 12px;
    }
}