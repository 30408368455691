.popover-menu-list {
    display: grid;
    grid-template-columns: 15% 85%;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 5px 2px;
    border-radius: 5px;

    &:hover {
        background-color: #efefef;
    }

    & div {
        align-self: center;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        
    }

}

.popover-profile-div {
    background-color: white;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    padding: 8px 0px 8px 8px;
    border-radius: 5px;
    margin-bottom: 10px;
    &:hover {
        background-color: #efefef;
    }
}