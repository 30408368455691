// .user-table {
// 	&__action {
// 		display: flex;
// 		gap: 20px;
// 		&--button{
// 			border-radius: 0px ;
// 		}
// 	}

// }

.previewBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  color: #fff;
  font-weight: 600;
  border-radius: 6px !important;
  gap: 8px;
  padding: 1.3rem 2.8rem;
  border: none;
}
