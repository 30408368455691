.ant-card .ant-card-body {
  padding: 1rem !important;
}

.conneted {
  border: 2px solid green !important;
}

.right-mark {
  position: absolute;
  top: 0;
  right: 0;
  // width: 20px;
  // height: 20px;
  background-color: green !important;
  // clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
  z-index: 1; /* Ensure the right mark is above the content */
}