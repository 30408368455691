.qbo-modal {
  &__header {
    padding: 2rem !important;
    display: flex;
    gap: 1.1rem;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    &-close {
      border: none !important;

      &:hover {
        cursor: pointer;
      }
    }
  }
  &-buttons {
    padding: 2rem;
    display: flex;
    gap: 2rem;
  }

  &__tags {
    height: calc(100vh - 34.9rem);
    overflow-y: scroll;
    padding: 2rem;
    border-bottom: 1px solid #ccc;

    &-tag {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }

    &-header {
      margin-bottom: 1rem;
      padding-left: 1rem;
    }

    // padding-bottom: 1rem;
  }
}
