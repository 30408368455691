.menu-item {
  background-color: #fff;

  .ant-menu-item {
    padding: 10px !important;
    margin: 0 !important;
    width: 100%;
    height: auto !important;
    gap: 8px;

    .ant-menu-title-content {
      font-size: 1.8rem !important;
      color: #000;
      font-weight: 400;
    }
  }

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      // margin-left: 9px;
      // font-size: 16px;
      padding: 30px 9px !important;
    }

    .ant-menu-title-content {
      margin-left: 9px !important;
      font-size: 1.8rem;
    }
  }

  .ant-menu-item-selected {
    background-color: #f3f8ff !important;
    margin: 0;
    border-left: 2px #0074ff solid;
    border-radius: unset;
    width: 100%;
  }

  .ant-menu-item-active {
    background-color: #f3f8ff !important;
    border-radius: unset;
  }

  .ant-menu-sub {
    margin-left: 20px;
  }
}

.ant-menu-title-content {
  margin: 0 !important;
}

.ant-layout-sider-children {
  height: 100%;
}

.ant-popover-content {
  width: 120%;
  height: 30vh;
}
