.dynamic-table {
  overflow: auto;
  height: 77vh !important;
  background-color: #fff !important;
  border-radius: 10px;

  &__permissions {
    margin-left: 0.981rem;
    color: #153fcd !important;
    font-size: 1.6rem !important;
    line-height: 2rem !important;
    font-weight: 600 !important;

    &:hover {
      cursor: pointer;
    }
  }

  &__granted-permission {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    &--text {
      font-size: 1.6rem;
      color: #009049;
      font-weight: 500;
    }
  }

  &__status {
    display: flex;
    align-items: center;
    color: #009049;
    gap: 0.836rem;
    font-size: 1.6rem !important;
    line-height: 2rem !important;
    font-weight: 600 !important;
  }

  &__no-permissions {
    font-size: 1.6rem !important;
    line-height: 2rem !important;
    font-weight: 600 !important;
    opacity: 55% !important;
    cursor: not-allowed;

    &--margin-left {
      margin-left: 0.981rem;
      color: #153fcd !important;
    }
  }

  &__no-action {
    cursor: not-allowed;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    opacity: 40%;
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 5px 20px;
  }
}
