
.profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    
    &__info {
        display: flex;
        gap: 15px;

        &-name {
            font-size: 18px;
            line-height: 27px;
            font-weight: 600;
        }

        &-email {
            font-size: 14px;
            line-height: 27px;
            font-weight: 400;
        }

    }

}
.twofa{
    &__title{
        &--des{
            color: gray;
            margin-bottom: 20px;
        }
    }
    &__subtitle{
       
    font-size: 2.1rem;
    font-weight: 500;
    margin-bottom: 25px;
    &--des{
        font-size: 1.7rem;
        margin-bottom: 20px;
    }
    &--textfield{
        font-size: 1.7rem;
        margin-bottom: 20px;
    }

    }
}

.profile-content {
    padding-top: 30px;

    &__label {
        color: #444444;
        font-size: 14px;
        font-weight: 400;
    }

    &__value {
        padding-top: 3px;
        font-size: 16px;
        font-weight: 400;
        color: #161616;
    }
}

.password {

    &-header{ 
        // font-family: Lexend;
        font-size: 24px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        padding-bottom: 20px;
    }

    &__input-field {

        .ant-select-selector{
            padding: 10px !important;
        }

        &__label {
            color: black;
            font-size: 16px;
            font-weight: 400;
        }
    }
}

.closebtn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: end;
    background-color: #f3f5f7;
  }