.main {
    width: 600px;
    margin: auto;
    padding: 30px;
}

.header {
    text-align: center;

    &__text {
        font-size: 30px;
        font-weight: 500;
        font-family: Lexend;
        line-height: 37.5px;
        padding-top: 30px;

        &-sub {
            font-size: 16px;
            font-weight: 400;
            font-family: Lexend;
            line-height: 20px;
            padding-top: 10px;
            color: #717171;
        }
    }
}

.body {
    padding-top: 20px;

    &__otp-submit {
        text-align: center;

        &__button {
            width: 230px;
            font-size: 18px;
            margin-top: 40px;
            cursor: pointer;
            background-color: #0b78c2;
            &:hover {
                    background: #0b78c2 !important;
                }
        }
    }

    &__otp-resend {
        text-align: center;
        padding-top: 35px;
        padding-bottom: 45px;
        border-bottom: 1px solid #d9d9d9;

        &-text {
            font-size: 16px;
            font-weight: 400;
            font-family: Lexend;
            line-height: 20px;
            padding-top: 10px;
            color: #666666;
        }
    }

    &__sign-in-with {
        padding: 40px 0px 45px 0px;
        display: flex;
        justify-content: center;
        gap: 10px;
        border-top: 1px solid #E5E5FF;
        border-bottom: 1px solid #E5E5FF;
        margin: 40px 0px;
        position: relative;

        &__or {
            position: absolute;
            top: -20px;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: #E5E5FF4D;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 17.5px;
            z-index: 9999;
        }

        &__xero {
            display: flex;
            align-items: center;
            color: inherit !important;
            border-color: #d9d9d9 !important;
            cursor: pointer;
        }

        &__intuit {
            color: inherit !important;
            background: #0b78c2;
            border: none !important;
            border-radius: 0.8rem;
            color: #fff !important;
            cursor: pointer;
        }
    }

    &__helper-text {
        color: #444444;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        font-family: Lexend;
    }

}

.link {
    text-decoration: underline;
    color: #0b78c2;
    margin-left: 10px;
    cursor: pointer;
    margin-left: 20px;

    &-disabled {
        color: #666666;
        text-decoration: underline;
        pointer-events: none;
        margin-left: 20px;
    }
}