.configuration {
  &-checkbox-div {
    &__title {
      font-size: 1.6rem !important;
      font-weight: 600;
      color: #27272e;
    }
    &__des {
      font-size: 1.6rem !important;
      // font-weight: 600;
      color: gray;
      padding-bottom: 4px;
    }
  }
  &__end {
    display: flex;
    // justify-content: right;
    margin-bottom: -20px;
    gap: 20;
    &-cancel {
      margin-top: 10px;

      display: flex;
      padding: 1.3rem 2.8rem;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 1px solid #000;
      box-shadow: none;
      font-size: 1.6rem;
      max-height: 41.8px;
      color: #000;
      font-weight: 600;
      border-radius: 6px !important;
      cursor: pointer;
      width: 13rem;
      &:hover {
        background: #fff !important;
        color: #27272e !important;
      }
      :hover {
        color: black !important;
      }
    }
    &-save {
      margin-right: 10px;
      background-color: #286fd1 !important;
      width: 13rem;
      margin-top: 10px;
      height: 41.8px;
    }
  }
}
