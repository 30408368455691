.side-drawer-form {
  &__inputs {
    padding-left: 3.636rem !important;
    padding-right: 3.636rem !important;
  }

  &__buttons {
    // margin-top: 35rem;
    padding-left: 3.636rem !important;
    padding-right: 3.636rem !important;
    display: flex;
    gap: 2rem;
  }

  &__save {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #000;
    color: #000;
    font-size: 1.6rem !important;
    font-weight: 600 !important;
    border-radius: 6px !important;
    cursor: pointer;
    width: 13rem;
    // min-height: 5.2rem;
    &:hover {
      color: #fff !important;
      background: #0b78c2 !important;
      border: 1px solid #0b78c2;
    }
  }
  &__save-and-add {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0b78c2;
    font-size: 1.6rem;
    color: #fff;
    font-weight: 600;
    border-radius: 6px !important;
    border: 1px solid #0b78c2;
    padding: 1.3rem 2.8rem;
    cursor: pointer;
    width: 18rem;
    max-height: 39.8px;
    &:hover {
      background: #0b78c2 !important;
    }
  }
  &__cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #000;
    box-shadow: none;
    font-size: 1.6rem !important;
    color: #000;
    font-weight: 600 !important;
    border-radius: 6px !important;
    cursor: pointer;
    width: 13rem;
    // min-height: 5.2rem;
    &:hover {
      background: #fff !important;
      color: #000 !important;
    }
  }

  &__single-input {
    margin-bottom: 2.5rem !important;
    &--label {
      font-weight: 600 !important;
      color: #27272e;
    }
    &--input {
      // margin-bottom: 2rem;
      margin-top: 1.2rem;
    }
    &--textarea {
      width: 100%;
      border-color: rgb(217, 217, 217);
      border-radius: 8px;
      padding: 11px 7px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.88);
      min-height: 9.6rem !important;
      font-weight: 400 !important;
      outline: none;
      margin-top: 0px;
      font-style: normal;

      &::placeholder {
        color: #c7c7c7;
      }
    }
  }
}

.ant-form-item-explain-error {
  background-color: black !important;
}
