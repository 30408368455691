@import "../../../scss/mixin.scss";

.login-body {
  &__wrapper {
  }
  &__top {
    &--title {
      font-weight: 700;
      font-size: 3.4rem;
      margin-bottom: 1.2rem;
      @include mq("desktop") {
        margin-bottom: 2.2rem;
      }
    }
    &--description {
      & > p {
        font-size: 17px;
        color: #425465;
        margin-bottom: 2.4rem;
        @include mq("desktop") {
          margin-bottom: 3.3rem;
        }
      }
    }
  }
  &__remberme-forgot {
    display: flex;
    align-items: center;
    &--remeber-me {
      font-size: 16px !important;
      margin-bottom: 2.5rem;
      font-weight: 500 !important;
      color: #27272E;
      width: max-content;
      margin-right: auto;
    }
    &--forgot-password {
      font-size: 16px;
      margin-bottom: 2.5rem;
      font-weight: 500;
      color: #ff0000;
      width: max-content;
      margin-left: auto;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__account-text {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
    &--text {
      margin-right: 1rem;
    }
    &--link {
      height: 25px;
      border-bottom: 2px solid #0b78c2;
    }
  }
  &__end {
    display: flex;
    text-align: center;
    justify-content: center;
    &--button {
      // min-width: 60% !important;
      font-size: 2rem !important;
      background: #0b78c2;
      height: 5.4rem !important;
      margin-bottom: 2.5rem;
      &:hover {
        background: #0b78c2 !important;
      }
    }
  }
  &__signin-options {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5rem;
    gap: 3rem;
    &--button-intuit {
      width: 100%;
      font-size: 2rem !important;
      background: #0b78c2;
      height: 5.4rem !important;
      margin-bottom: 2.5rem;
      &:hover {
        background: #0b78c2 !important;
      }
    }
    &--button-xero {
      width: 100%;
      font-size: 2rem !important;
      height: 5.4rem !important;
      margin-bottom: 2.5rem;
      color: #4e5562;
      &:hover {
        color: #4e5562 !important;
      }
    }
    img {
      vertical-align: sub;
      margin-right: 1rem;
    }
  }
}
