.search-and-filter {
  display: flex;
  // gap: 2rem;
  justify-content: space-between;
}

.search-and-filter-left {
  display: flex;
  gap: 10px;
}
