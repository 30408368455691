.select-dropdown {
  margin-top: 13px;

  &__label-div {
    margin-bottom: 4px;
  }

  &__label {
    font-weight: 500;
  }
}

.form {
  &__btn {
    margin-top: 15px;

    &--save {
      margin-right: 8px;
      background-color: #0b78c2;
      &:hover {
        background: #0b78c2 !important;
      }
    }
    &--cancel {
      &:hover {
        color: black !important;
      }
    }
  }
}

.other-erorr{
  color: #ff4d4f !important;
}

