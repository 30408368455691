.login {
  height: 100vh;
  &__wrapper {
    height: 100%;
  }
  &__details {
    height: 100%;
    background-color: rgba(64, 150, 255, 0.02);
    
   
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &--body{
    min-width: 57.4rem;
    // padding-top: 12.8rem;
    margin: 0% auto 0% 10%;
    }
  }
  &__layout {
    background-color: white;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
