.dynamic-table {
  overflow: auto;
  background-color: #fff !important;
  border-radius: 10px;
  height: 77vh !important;
  &__pagination {
    justify-content: center !important;
    .ant-pagination-item {
      border-radius: 50%;
    }
    .ant-pagination-item-active {
      border-color: #000;
      background-color: #000;
      &:hover {
        border: none !important;
        border-width: 0px !important;
      }
      a {
        color: #fff !important;
      }
    }
  }
  .table-edit-icon,
  .table-delete-icon {
    cursor: pointer;
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 5px 20px !important;
  }
}

// /* For All the tables */
// .ant-table-container {
//   height: calc(-75px + 70vh) !important;
// } 