.main {
    width: 700px;
    margin: auto;
    padding: 30px;
}

.header {
    text-align: center;

    &__text {
        font-size: 30px;
        font-weight: 500;
        font-family: Lexend;
        line-height: 37.5px;
        padding-top: 30px;
    }
}

.body {
    padding-top: 20px;

    &__email-submit {
        text-align: center;

        &__button {
            width: 470px;
            font-size: 18px;
            margin-top: 40px;
            background-color: #0b78c2;
            cursor: pointer;
            &:hover {
                    background: #0b78c2 !important;
                }
        }
    }

    &__sign-in-with {
        padding: 40px 0px 45px 0px;
        display: flex;
        justify-content: center;
        gap: 10px;
        border-top: 1px solid #E5E5FF;
        border-bottom: 1px solid #E5E5FF;
        margin: 40px 0px;
        position: relative;

        &__or {
            position: absolute;
            top: -20px;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: #E5E5FF;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 17.5px;
            z-index: 9999;
        }

        &__xero {
            display: flex;
            align-items: center;
            color: inherit !important;
            border-color: #d9d9d9 !important;
            cursor: pointer;
        }

        &__intuit {
            color: inherit !important;
            background: #0b78c2;
            border: none !important;
            border-radius: 0.8rem;
            color: #fff !important;
            cursor: pointer;
            &:hover {
                    background: #0b78c2 !important;
                }
        }
    }

    &__helper-text {
        color: #7a7979;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        font-family: Lexend;

        a {
            color: inherit;
        }

        &-sub {
            margin-top: 20px;
            color: #7a7979;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            font-family: Lexend;
            display: flex;
            align-items: center;
            justify-content: center;

            a {
                color: #2477FD;
                text-decoration: none;
            }
        }
    }

    &__steps {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        position: relative;
        width: 600px;
        height: 147px;
        margin: auto;
        margin-top: 40px;

        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__text {
                padding-top: 30px;
                font-size: 12px;
                color: #7a7979;
                font-weight: 400;
                font-family: Lexend;
                text-align: center;
            }
        }
    }

}

.emailError {
    color: #ff4d4f !important;
    font-size: 12px !important;
    padding-left:10px ;
    padding-top: 4px;
}